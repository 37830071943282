import React from "react"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../components/layout"
import Plans from "../components/premium/plans"
import SEO from "../components/seo"
import { createCheckoutSessionAction, userSelector, UserState } from "../hasura/slices/user"

export default function PremiumPage() {
  const dispatch = useDispatch()

  const { accessToken, user }: UserState = useSelector(userSelector)

  const createCheckoutSession = () => dispatch(createCheckoutSessionAction(accessToken!, user?.related_accounts?.stripe_profile?.id!))

  return (
    <Layout noStyles>
      <SEO title="Premium" />

      <div style={{ height: "50vh" }} className="position-relative flex-center">
        <div style={{ left: "-50vw", marginLeft: "50%" }} className="vw-100 h-100 position-absolute bg--purple z-100-neg" />

        <h1 style={{ maxWidth: "800px" }} className="px-3 text-center text-xxxl text-white bold">
          Unlock the entire Wordcraft universe of words, images, and passages.
        </h1>
      </div>

      <div className="my-5 flex-center flex-column">
        <h1 className="text-center text-xxxl mb-4 bold">Plans and pricing</h1>

        <Plans />
      </div>

      <div className="position-relative text-center py-6">
        <div
          style={{ padding: "inherit", backgroundColor: "#91f4ce", left: "-50vw", marginLeft: "50%" }}
          className="vw-100 h-100 t-0 position-absolute z-100-neg"
        />

        <h1 className="text-center text-xxxl bold mb-5">What to expect</h1>

        <div style={{ maxWidth: "800px" }} className="m-0-auto">
          <div className="d-flex mobile-flex-column justify-content-center border-bottom border-white">
            <div className="p-5 border-white border-right flex-even">
              <h2 className="bold">New content regularly</h2>
              <p className="mt-1 gray8 text-m">Words, images, and passages are always being added to our curriculum.</p>
            </div>
            <div className="p-5 flex-even">
              <h2 className="bold">Wide variety of topics</h2>
              <p className="mt-1 gray8 text-m">Spark curiosity with our worldly library of knowledge.</p>
            </div>
          </div>

          <div className="m-0-auto d-flex mobile-flex-column justify-content-center">
            <div className="p-5 border-white border-right flex-even">
              <h2 className="bold">In depth reports</h2>
              <p className="mt-1 gray8 text-m">View progress with download-able and share-able reports.</p>
            </div>
            <div className="p-5 flex-even">
              <h2 className="bold">Easy to use</h2>
              <p className="mt-1 gray8 text-m">Setup games, assign homework, download reports, all with a few clicks.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center py-5 my-5">
        <h3 className="text-xxl bold">Get started with our Premium Plan</h3>

        <p className="gray8 text-m">Access thousands of words and passages today!</p>

        <Button onClick={createCheckoutSession} size="lg" color="success">
          Subscribe
        </Button>
      </div>
    </Layout>
  )
}
